import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FAQItem } from '../faq-item.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SettingsService } from '../../../services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-faq',
  imports: [TranslateModule, NgClass],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  animations: [
    trigger('slideToggle', [
      state(
        'collapsed',
        style({
          width: '55px',
          right: '0',
          transform: 'translateX(0)',
        }),
      ),
      state(
        'expanded',
        style({
          width: '100%',
          right: '0',
          transform: 'translateX(0)',
        }),
      ),
      transition('collapsed <=> expanded', [
        animate('0.5s cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
    ]),
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: '*' })),
      state('out', style({ opacity: 0, height: '0' })),
      transition('in <=> out', animate('0.5s ease')),
    ]),
    trigger('fadeOverlay', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit {
  readonly showFAQChange = output<boolean>();
  protected showFAQ = false;
  protected faqItems: FAQItem[] = [];
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getFaqItems();
    this.listenToRouteChanges();
  }

  toggleFAQ() {
    this.showFAQ = !this.showFAQ;
    this.showFAQChange.emit(this.showFAQ);

    if (this.showFAQ) {
      setTimeout(() => {
        const faqButton = document.querySelector('.faq-button');
        if (faqButton) {
          faqButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  }

  toggleItem(index: number) {
    this.faqItems[index].isOpen = !this.faqItems[index].isOpen;

    const isLastItem = index === this.faqItems.length - 1;
    const isItemOpen = this.faqItems[index].isOpen;

    if (isLastItem && isItemOpen) {
      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight + 100,
          behavior: 'smooth',
        });
      }, 100);
    }
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.showFAQ) {
          this.closeFAQ();
        }
      });
  }

  private getFaqItems() {
    this.settingsService
      .getFaqItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          if (data && data.faq && data.faq.length > 0) {
            this.faqItems = data.faq.map((item) => {
              item.isOpen = false;
              return item;
            });
          }
        },
      });
  }

  private closeFAQ(): void {
    this.showFAQ = false;
    this.showFAQChange.emit(this.showFAQ);
    this.cd.detectChanges();
  }
}
